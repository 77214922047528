import React, { useEffect, useState } from "react";
import "./externalWebSite.css";
import { getUser, verifyUser, getVisits } from "./firebase";
import { useNavigate } from "react-router-dom";

const ExternalSite = () => {
  const [userData, setUserData] = useState({
    fullName: null,
    numberOfVisits: null,
  });
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      const savedEmail = localStorage.getItem("userEmail");
      if (savedEmail != null) {
        const result = await verifyUser(savedEmail);
        if (result === true) {
          const currUserData = await getUser(savedEmail);
          console.log("made request!");
          if (currUserData !== null || currUserData !== undefined) {
            setUserData(currUserData);
          }
        }
      } else {
        navigate("/");
      }
    }
    fetchData();
  }, [navigate]);

  const userName = userData.fullName;

  const logoutUser = () => {
    try {
      window.ReactNativeWebView.postMessage("logout");
    } catch (e) {
      console.log(e, "probably not in app");
    }
    localStorage.removeItem("userEmail");
    navigate("/");
  };

  const externalSiteNav = () => {
    navigate("/mainPage", { state: userData });
  };

  return (
    <div className="user-profile-page1">
      <nav className="nav-bar">
        <button className="logout-button" onClick={logoutUser}>
          התנתקות
        </button>
        <button className="logout-button" onClick={externalSiteNav}>
          הכרטיסיה שלי
        </button>
      </nav>
      <iframe
        style={{ width: "100vw", height: "100vh", marginTop: "9vh" }}
        src="https://danoaesthetics.com/"
      ></iframe>
    </div>
  );
};

export default ExternalSite;
