import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SignIn from "./sign-in";
import MainPage from "./mainPage";
import AdminPage from "./admin";
import ExternalSite from "./externalWebSite";
const App = () => {
  document.addEventListener("ReceivedUserData", (event) => {
    console.log("User data received in web:", event.detail.email);
    //! improve!!!!!!!!
    localStorage.setItem("userEmail", event.detail.email);
  });

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<SignIn />} />
        <Route path="/MainPage" element={<MainPage />} />
        <Route path="/AdminPage" element={<AdminPage />} />
        <Route path="/Danoaesthetics" element={<ExternalSite />} />
      </Routes>
    </Router>
  );
};

export default App;
