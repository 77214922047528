import React, { useEffect, useState } from "react";
import "./mainPage.css";
import homeAbout from "./assets/homeAbout.png";
import { getVisits } from "./firebase";
import { useNavigate, useLocation } from "react-router-dom";

const MainPage = () => {
  const location = useLocation();
  const userData = location.state;
  const [checkpoints, setCheckpoints] = useState([]);
  const navigate = useNavigate();

  async function getTimeline() {
    //? checkpoits fill
    let timeline = await getVisits(userData.email);
    timeline.sort((a, b) => a.visits - b.visits);
    console.log(timeline);
    setCheckpoints(timeline);
  }

  useEffect(() => {
    getTimeline();
  }, []);

  const logoutUser = () => {
    try {
      window.ReactNativeWebView.postMessage("logout");
    } catch (e) {
      console.log(e, "probably not in app");
    }
    localStorage.removeItem("userEmail");
    navigate("/");
  };

  const externalSiteNav = () => {
    navigate("/Danoaesthetics");
  };

  console.log(userData);
  const userName = userData.fullName;
  const visits = userData.numberOfVisits;

  return (
    <div className="user-profile-page">
      <nav className="nav-bar">
        <button className="logout-button" onClick={logoutUser}>
          התנתקות
        </button>
        <button
          style={{
            backgroundImage: `url(${homeAbout})`,
            backgroundSize: "cover",
            width: "40px",
            height: "40px",
            border: "0px solid",
            padding: "10px 20px",
            cursor: "pointer",
            backgroundColor: "transparent",
            "font-size": "16px",
          }}
          //className="logout-button"
          onClick={externalSiteNav}
        ></button>
      </nav>
      <div className="timeline">
        {checkpoints.map((checkpoint, index) => (
          <div
            className={`progressBar timeline__event animated fadeInUp delay-${
              3 - index
            }s ${index === 0 ? "firstTimeLineBlock" : ""} `}
          >
            <div className="prog-content">
              <h3>{checkpoint.headline}</h3>
              <p>{checkpoint.description}</p>
            </div>
            <div
              className={`${
                visits >= checkpoint.visits
                  ? "timeline__event--achieved"
                  : "timeline__event--unachieved"
              } circle`}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainPage;
