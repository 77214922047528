import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  runTransaction,
  getDoc,
  setDoc,
  getDocs,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const isExist = await verifyUser(result.user.email);
    if (isExist === true) localStorage.setItem("userEmail", result.user.email);
    return isExist;
  } catch (e) {
    console.error(e);
    return "error";
  }
};

export const addUserToFirestore = async (userName, userPhoneNumber) => {
  const result = await signInWithPopup(auth, provider);

  localStorage.setItem("userEmail", result.user.email);
  let db = getFirestore(app);
  const isExist = await verifyUser(result.user.email);
  if (isExist === true) {
    return "User already exists!";
  } else {
    const usersRef = collection(db, "users");
    await setDoc(doc(usersRef, result.user.email), {
      email: result.user.email,
      fullName: userName,
      phoneNumber: userPhoneNumber,
      numberOfVisits: 0,
      isAdmin: false,
    });

    //? feaching standard roadmap
    let sortedMainoadList = null;
    try {
      const mainRoadmapRef = collection(db, `standardRoadmap`);
      const mainRoadmaQuerySnapshot = await getDocs(mainRoadmapRef);
      const mainRoadmaList = mainRoadmaQuerySnapshot.docs.map((doc) =>
        doc.data()
      );
      sortedMainoadList = mainRoadmaList.sort((a, b) => a.visits - b.visits);
    } catch (e) {
      console.error("error feaching standard roadmap:", e);
    }

    const userRoadmapRef = collection(db, `users/${result.user.email}/roadmap`);
    for (let i = 1; i <= 10; i++) {
      await setDoc(doc(userRoadmapRef, `visit${i}`), {
        headline: sortedMainoadList[i - 1].headline,
        secondHeadline: sortedMainoadList[i - 1].secondHeadline,
        description: sortedMainoadList[i - 1].description,
        visits: i,
      });
    }
    return "Sign up successful!";
  }
};

export const verifyUser = async (userEmail) => {
  let db = getFirestore(app);
  const docRef = doc(db, "users", userEmail);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() === true) {
    return true;
  } else {
    return false;
  }
};

export const getVisits = async (userEmail) => {
  try {
    let db = getFirestore(app);
    const usersRef = collection(db, `users/${userEmail}/roadmap`);
    const querySnapshot = await getDocs(usersRef);
    const userList = querySnapshot.docs.map((doc) => doc.data());
    return userList;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const getVisitsMainRoadmap = async (userEmail) => {
  try {
    let db = getFirestore(app);
    const mainRoadmapRef = collection(db, `standardRoadmap`);
    const mainRoadmaQuerySnapshot = await getDocs(mainRoadmapRef);
    const mainRoadmaList = mainRoadmaQuerySnapshot.docs.map((doc) =>
      doc.data()
    );
    return mainRoadmaList;
  } catch (e) {
    console.error("error feaching standard roadmap:", e);
  }
};

export const getUser = async (userEmail) => {
  const db = getFirestore(app);
  const docRef = doc(db, "users", userEmail);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() === true) {
    return docSnap.data();
  } else {
    return false;
  }
};

export const isUserAdmin = async (userEmail) => {
  let db = getFirestore(app);
  const docRef = doc(db, "users", userEmail);
  const docSnap = await getDoc(docRef);
  return await docSnap.data().isAdmin;
};

export const fetchUsers = async () => {
  try {
    let db = getFirestore(app);
    const usersRef = collection(db, "users");
    const querySnapshot = await getDocs(usersRef);
    const userList = querySnapshot.docs.map((doc) => doc.data());
    return userList;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const setUserData = async (User) => {
  try {
    let db = getFirestore(app);
    const usersRef = collection(db, "users");
    setDoc(doc(usersRef, User.email), {
      email: User.email,
      fullName: User.fullName,
      phoneNumber: User.phoneNumber,
      numberOfVisits: User.numberOfVisits,
      isAdmin: User.isAdmin,
    });
  } catch (error) {
    console.error("Error users:", error);
  }
};

export const setVisitDataMainRoadmap = async (visit) => {
  try {
    //updating the main firestore table
    let db = getFirestore(app);
    const usersRef = collection(db, `standardRoadmap`);
    setDoc(doc(usersRef, "visit" + visit.visits), {
      headline: visit.headline,
      secondHeadline: visit.secondHeadline,
      description: visit.description,
      visits: visit.visits,
    });

    //updating each user

    const newValues = {
      headline: visit.headline,
      secondHeadline: visit.secondHeadline,
      description: visit.description,
      visits: visit.visits,
    };
    const usersCollection = collection(db, "users");
    // Get all users
    const users = await getDocs(usersCollection);
    // Update each user's document
    users.forEach(async (user) => {
      // Get the user's roadmap collection reference
      const roadmapCollection = collection(db, `users/${user.id}/roadmap`);
      // Update the document's values
      setDoc(doc(roadmapCollection, "visit" + newValues.visits), {
        headline: newValues.headline,
        secondHeadline: newValues.secondHeadline,
        description: newValues.description,
        visits: newValues.visits,
      });
    });
  } catch (error) {
    console.error("Error users:", error);
  }
  alert("Success");
};

export const setVisitData = async (userEmail, visit) => {
  try {
    let db = getFirestore(app);
    const usersRef = collection(db, `users/${userEmail}/roadmap`);
    setDoc(doc(usersRef, "visit" + visit.visits), {
      headline: visit.headline,
      secondHeadline: visit.secondHeadline,
      description: visit.description,
      visits: visit.visits,
    });
  } catch (error) {
    console.error("Error users:", error);
  }
};
