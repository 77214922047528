import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBInput,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import "mdbreact/dist/css/mdb.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./sign-in.css";
import logo from "./assets/logo.png";
import {
  signInWithGoogle,
  addUserToFirestore,
  verifyUser,
  isUserAdmin,
} from "./firebase";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
    async function fetchData() {
      const savedEmail = localStorage.getItem("userEmail");
      if (savedEmail != null) {
        const result = await verifyUser(savedEmail);
        if (result === true) {
          if ((await isUserAdmin(savedEmail)) === true) {
            navigate("/AdminPage");
          } else {
            navigate("/Danoaesthetics");
          }
        } else console.log("please create an account");
      }
    }
  }, [navigate]);

  const [inputName, setInputName] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [justifyActive, setJustifyActive] = useState("tab1");

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }
    setJustifyActive(value);
  };

  const userSave = async () => {
    if (inputName === "" || inputPhone === "") {
      alert("בבקשה הכנס שם ומספר טלפון");
      return;
    }
    const result = await addUserToFirestore(inputName, inputPhone);
    //display result massege...
    console.log(result);
    navigate("./Danoaesthetics");
  };

  const handleGoogleButtonClick = async () => {
    try {
      const isExist = await signInWithGoogle();
      if (isExist === "error") {
        return;
      }
      if (isExist === true) {
        const savedEmail = localStorage.getItem("userEmail");
        if ((await isUserAdmin(savedEmail)) === true) {
          navigate("/AdminPage");
        } else {
          navigate("/Danoaesthetics");
        }
      } else {
        alert("חשבון לא קיים, בבקשה הירשם וצור חשבון.");
        handleJustifyClick("tab2");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="gradient-background " style={{ height: "100vh" }}>
      <MDBContainer className="p-3 my-4 d-flex flex-column w-60">
        <img src={logo} className="App-logo" alt="logo" />
        <MDBTabs
          pills
          justify
          className="mb-3 my-4 d-flex flex-row justify-content-between"
        >
          <MDBTabsItem className="custom-tab-item">
            <MDBTabsLink
              onClick={() => handleJustifyClick("tab1")}
              active={justifyActive === "tab1"}
            >
              התחברות
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem className="custom-tab-item">
            <MDBTabsLink
              onClick={() => handleJustifyClick("tab2")}
              active={justifyActive === "tab2"}
            >
              הרשמה
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <hr />
        <MDBTabsContent>
          <MDBTabsPane show={justifyActive === "tab1"}>
            <button
              style={{ borderColor: "#B64958", background: "#e9c8cc" }}
              className="ripple ripple-surface ripple-surface-light btn  btn-lg mb-4 w-100"
              role="button"
              onClick={handleGoogleButtonClick}
            >
              <i data-test="fa" className="fab fa-google mx-2"></i>להמשיך עם
              גוגל
            </button>
          </MDBTabsPane>
          <MDBTabsPane show={justifyActive === "tab2"}>
            <MDBInput
              onChange={(e) => setInputName(e.target.value)}
              wrapperClass="mb-4"
              label="שם מלא"
              id="form1"
              type="text"
            />
            <MDBInput
              onChange={(e) => setInputPhone(e.target.value)}
              wrapperClass="mb-4"
              label="מס׳ טלפון"
              id="form2"
              type="tel"
            />

            <button
              style={{ background: "#B64958", color: "white" }}
              className="ripple ripple-surface ripple-surface-light btn w-100"
              role="button"
              onClick={userSave}
            >
              הרשמה
            </button>
          </MDBTabsPane>
        </MDBTabsContent>
      </MDBContainer>
    </div>
  );
}

export default SignIn;
