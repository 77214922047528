import React, { useEffect, useState } from "react";
import {
  fetchUsers,
  setUserData,
  isUserAdmin,
  getVisits,
  getVisitsMainRoadmap,
  setVisitDataMainRoadmap,
  setVisitData,
} from "./firebase";
import "./admin.css";
import { useNavigate } from "react-router-dom";

function AdminPage() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [editAllModalOpen, setEditAllModalOpen] = useState(false);

  useEffect(() => {
    async function getUserData() {
      setUsers(await fetchUsers());
    }
    async function verifyUser() {
      const savedEmail = localStorage.getItem("userEmail");
      if ((await isUserAdmin(savedEmail)) === false) {
        navigate("/");
      }
    }
    verifyUser();
    getUserData();
  }, [navigate]);

  const handleViewDetails = (user) => {
    setSelectedUser(user);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phoneNumber.includes(searchTerm)
  );

  const logoutUser = () => {
    try {
      window.ReactNativeWebView.postMessage("logout");
    } catch (e) {
      console.log(e, "probably not in app");
    }
    localStorage.removeItem("userEmail");
    navigate("/");
  };

  const handleSetEditAllModalOpen = () => {
    if (editAllModalOpen === false) {
      setEditAllModalOpen(true);
    } else if (editAllModalOpen === true) {
      setEditAllModalOpen(false);
    }
  };

  return (
    <div className="container1">
      <nav className="nav-bar1">
        <button className="logout-button button" onClick={logoutUser}>
          התנתקות
        </button>
        <div style={{ "font-size": "125%" }} className="user-details">
          היי עמית
        </div>
      </nav>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          "justify-content": "center",
        }}
      >
        <div style={{ padding: "20px", width: "50%" }}>
          <h1>Admin Page</h1>
          <div className="searchbarContainer">
            <input
              type="text"
              placeholder="Search users by name or phone..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-bar"
            />
            <button
              className="editAllBtn button"
              onClick={handleSetEditAllModalOpen}
            >
              ערוך לכולם
            </button>
          </div>
          <ul className="user-list">
            {filteredUsers.map(
              (
                user,
                index // Use filteredUsers instead of users
              ) => (
                <li
                  key={index}
                  className="user-item"
                  onClick={() => handleViewDetails(user)}
                >
                  <div
                    style={{
                      display: "flex",
                      "justify-content": "space-between",
                    }}
                  >
                    <strong>Name:</strong> <text>{user.fullName}</text>
                    <strong>Phone:</strong> <text>{user.phoneNumber}</text>
                  </div>
                </li>
              )
            )}
            {filteredUsers.length === 0 && <li>No users found...</li>}
          </ul>
          {selectedUser && (
            <UserModal user={selectedUser} onClose={handleCloseModal} />
          )}
          {editAllModalOpen && (
            <EditAllModal onClose={handleSetEditAllModalOpen}></EditAllModal>
          )}
        </div>
      </div>
    </div>
  );
}

const UserModal = ({ user, onClose }) => {
  const [displayedUser, setDisplayedUser] = useState(user);
  const [isRoadMapModalOpen, setRoadMapModalOpen] = useState(false);
  const [visitsModalOpen, setvisitsModalOpen] = useState(false);
  const [visits, setVisits] = useState([]);
  const [selectedVisit, setselectedVisit] = useState();

  const [newHeadLine, setnewHeadLine] = useState("");
  const [newSecondHeadLine, setnewSecondHeadLine] = useState("");
  const [newDiscription, setnewDiscription] = useState("");

  const handleViewDetails = (user) => {
    setselectedVisit(user);
  };
  const handleCloseModal = () => {
    setselectedVisit(null);
  };

  const toggleRoadMapModal = () => {
    setRoadMapModalOpen(!isRoadMapModalOpen);
  };
  const toggleVisitModal = () => {
    setvisitsModalOpen(!visitsModalOpen);
  };
  useEffect(() => {
    getVisists();
  }, []);

  async function getVisists() {
    let timeline = await getVisits(user.email);
    timeline.sort((a, b) => a.visits - b.visits);
    setVisits(timeline);
  }

  const handleButtonClick = (user, val) => {
    if (user.numberOfVisits + val >= 0) {
      const updatedUser = {
        fullName: user.fullName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        numberOfVisits: user.numberOfVisits + val,
        isAdmin: user.isAdmin,
      };
      setUserData(updatedUser);
      setDisplayedUser(updatedUser);
    }
  };
  const saveVisit = () => {
    let tempSecondHeadLine = newSecondHeadLine;
    let tempDiscription = newDiscription;
    if (tempSecondHeadLine === "") {
      tempSecondHeadLine = selectedVisit.secondHeadline;
    }
    if (tempDiscription === "") {
      tempDiscription = selectedVisit.description;
    }

    const updatedVisit = {
      headline: tempSecondHeadLine,
      visits: selectedVisit.visits,
      secondHeadline: tempSecondHeadLine,
      description: tempDiscription,
    };
    setVisitData(user.email, updatedVisit);
    setselectedVisit(updatedVisit);
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <div>
          <strong>Name:</strong> {displayedUser.fullName}
        </div>
        <div>
          <strong>Phone:</strong> {displayedUser.phoneNumber}
        </div>
        <div>
          <strong>Email:</strong> {displayedUser.email}
        </div>
        <div>
          <strong>Number Of Visits:</strong> {displayedUser.numberOfVisits}
        </div>
        <button
          className="modal-button"
          onClick={() => handleButtonClick(displayedUser, 1)}
        >
          Add visit
        </button>
        <div className="button-container">
          <button
            className="modal-button2"
            onClick={() => handleButtonClick(displayedUser, -1)}
          >
            Reduct visit
          </button>
          <button
            className="modal-button2"
            onClick={() =>
              handleButtonClick(displayedUser, -displayedUser.numberOfVisits)
            }
          >
            Reset
          </button>
        </div>
        <button className="modal-button3" onClick={toggleRoadMapModal}>
          Edit roadmap
        </button>
      </div>
      {isRoadMapModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleRoadMapModal}>
              &times;
            </span>
            {visits.map((visit, index) => (
              <div>
                <li
                  key={index}
                  className="user-item2"
                  onClick={() => {
                    toggleVisitModal(visit);
                    handleViewDetails(visit);
                  }}
                >
                  <div>
                    <strong>Visits:</strong> {index + 1},{" "}
                    <strong>headline:</strong> {visit.headline}
                  </div>
                </li>
              </div>
            ))}

            {}
          </div>
        </div>
      )}
      {visitsModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleVisitModal}>
              &times;
            </span>
            <strong>secondHeadline:</strong> {selectedVisit.secondHeadline}
            <input
              className="search-bar"
              onChange={(e) => setnewSecondHeadLine(e.target.value)}
            ></input>
            <strong>description:</strong> {selectedVisit.description}
            <input
              className="search-bar"
              onChange={(e) => setnewDiscription(e.target.value)}
            ></input>
            <button className="modal-button" onClick={() => saveVisit()}>
              save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const EditAllModal = ({ onClose }) => {
  const [visits, setVisits] = useState([]);
  const [visitsModalOpen, setvisitsModalOpen] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState();

  const [newSecondHeadLine, setnewSecondHeadLine] = useState("");
  const [newDiscription, setnewDiscription] = useState("");

  useEffect(() => {
    _getVisitsMainRoadmap();
  }, []);

  async function _getVisitsMainRoadmap() {
    let timeline = await getVisitsMainRoadmap();
    timeline.sort((a, b) => a.visits - b.visits);
    setVisits(timeline);
  }

  const toggleVisitModal = () => {
    setvisitsModalOpen(!visitsModalOpen);
  };

  const handleViewDetails = (visit) => {
    setSelectedVisit(visit);
  };

  const saveVisit = () => {
    let tempSecondHeadLine = newSecondHeadLine;
    let tempDiscription = newDiscription;
    if (tempSecondHeadLine === "") {
      tempSecondHeadLine = selectedVisit.secondHeadline;
    }
    if (tempDiscription === "") {
      tempDiscription = selectedVisit.description;
    }

    const updatedVisit = {
      headline: tempSecondHeadLine,
      visits: selectedVisit.visits,
      secondHeadline: tempSecondHeadLine,
      description: tempDiscription,
    };
    setVisitDataMainRoadmap(updatedVisit);
    setSelectedVisit(updatedVisit);
    toggleVisitModal();
  };

  return (
    <div className="modal">
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          {visits.map((visit, index) => (
            <div>
              <li
                key={index}
                className="user-item2"
                onClick={() => {
                  toggleVisitModal(visit);
                  handleViewDetails(visit);
                }}
              >
                <div>
                  <strong>Visits:</strong> {index + 1},{" "}
                  <strong>headline:</strong> {visit.headline}
                </div>
              </li>
            </div>
          ))}

          {}
        </div>
      </div>

      {visitsModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleVisitModal}>
              &times;
            </span>
            <strong>Headline:</strong> {selectedVisit.secondHeadline}
            <input
              className="search-bar"
              onChange={(e) => setnewSecondHeadLine(e.target.value)}
            ></input>
            <strong>Description:</strong> {selectedVisit.description}
            <input
              className="search-bar"
              onChange={(e) => setnewDiscription(e.target.value)}
            ></input>
            <button className="modal-button" onClick={() => saveVisit()}>
              save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
